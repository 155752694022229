<template>
  <div class="monitor-main">
    <div class="monitor-tree" v-if="!collect">
      <StationTree v-if="currentMenuName == 'light'" />
      <CommonTree v-else />
    </div>
    <div class="monitor-content" :style="{paddingTop: `${currentMenuName == 'light' ? 0 : 15}px`, paddingRight: `${currentMenuName == 'light' ? 0 : 15}px`, paddingBottom: `${currentMenuName == 'light' ? 0 : 15}px`}">
      <div v-if="showCollect" :class="[!collect ? 'left-spliter' : 'right-spliter']" @click="collectTree">
      </div>
      <div class="content-header" v-if="currentMenuName != 'gis' && currentMenuName != 'light'">
        <div :class="['header-item', currentMenu == item.code ? 'header-selected' : '', idx == currentMenus.length - 1 ? 'header-last-item' : '']" v-for="(item, idx) in currentMenus" :key="idx" @click="switchMenu(item)">{{item.name}}</div>
        <!-- {{currentMenuName}} -->
      </div>
      <div class="content-body">
        <LightIndex v-if="currentMenuName == 'light'" />
        <template v-else v-for="(item, idx) in currentMenus">
          <component v-if="item.code == currentMenu && item.com" :key="idx" v-bind:is="item.com" :args="item.args" @switchMenu="switchTopMenu"></component>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import CommonTree from './tree/Index'
import StationTree from './monitor/station/tree/Index'
import MapIndex from './map/Index'
// import LightIndex from './light/Index'
import LightIndex from '../light/road/monitor/Content'
import PoleMonitorIndex from './monitor/main/Index'
import PoleParamIndex from './monitor/param/Index'
import externalPowerIndex from './monitor/externalPower/Index'
// import PoleMediaIndex from './monitor/media/Index'
import PoleStationConfig from './monitor/station/Index'
import PoleControlIndex from './monitor/control/Index'
// import PoleAssetIndex from './monitor/asset/Index'


import SpeakHistoryIndex from './speak/history/Index'
// import SpeakConfigIndex from './speak/config/Index'
// import SpeakMonitorIndex from './speak/Index'
import SpeakMonitorIndex2 from './speak/Index2'

// import LedControlIndex from './led/control/Index'
import LedControlIndex from '@/components/control/pole/led/Index'
import LedControlIndex2 from './led/Index2'
import ledHistoryIndex from './led/history/Index'
import VideoControlIndex from './video/control/Index'

// import EnvControlIndex from './env/control/Index'
import EnvControlIndex from '@/components/control/pole/env/Index'
// import EnvConfigIndex from './env/config/Index'
import EnvHistoryIndex from './env/history/Index'
// import EnvMonitorIndex from './env/Index'
import EnvMonitorIndex2 from './env/Index2'

// import WifiMonitorIndex from './wifi/Index'
import WifiMonitorIndex2 from './wifi/Index2'
// import WifiHistoryIndex from './wifi/history/Index'

// import ChargeMonitorIndex from './charge/Index'
import ChargeMonitorIndex2 from './charge/Index2'
// import ChargeHistoryIndex from './charge/history/Index'
import ChargeLogIndex from './charge/logs/Index'
// import ChargeConfigIndex from './charge/config/Index'

// import ManholeMonitorIndex from './manhole/Index'
import ManholeMonitorIndex2 from './manhole/Index2'
import ManholeHistoryIndex from './manhole/history/Index'

// import GarbageMonitorIndex from './garbage/Index'
import GarbageMonitorIndex2 from './garbage/Index2'
import GarbageHistoryIndex from './garbage/history/Index'

// import PhoneMonitorIndex from './phone/Index'
import PhoneMonitorIndex2 from './phone/Index2'
import PhoneHistoryIndex from './phone/history/Index'

import VideoAnalysisIndex from './video/analysis/Index'
import VideoVehicleIdIndex from './video/vehicleId/Index'
import VideoFaceIdIndex from './video/face/Index'
import VideoHistoryIndex from './video/history/Index'
// import VideoConfigIndex from '@/views/pole/video/config/Index'

// import LightAnalyseIndex from './light/analyse/Index'
// import LightControlIndex from './light/control/Index'

// import BroadOverviewIndex from './broad/overview/Index'
import BroadOverviewIndex2 from './broad/overview/Index2'
// import BroadProgramIndex from './broad/program/Index'
import BroadHistoryIndex from './broad/history/Index'
// import BroadTaskIndex from './broad/control/Task'
// import BroadControlIndex from './broad/control/Index'
import BroadControlIndex from '@/components/control/pole/broad/Index'
import BroadTaskIndex from '@/components/control/pole/broad/Task'


// import MediaAssetIndex from '@/views/media/asset/Index'
import MediaTaskIndex from '@/views/media/task/Index'

import AnalyseLightOnIndex from './analyse/lighton/Index'
import AnalyseEnergyIndex from './analyse/energy/Index'
import AnalyseAlarmIndex from './analyse/alarm/Index'

import UnionIndex from './union/Index'
import ConfigAccountIndex from '@/views/common/account/Index'



import Working from './Working'
export default {
  name: 'Home',
  components: {
    CommonTree,
    LightIndex,
    StationTree,
  },
  data() {
    return {
      collect: false,
      showCollect: true,
      currentMenu: 's1',
      menus: {
        gis: [
          { code: 's1', name: 'GIS地图', com: MapIndex, args: {} },
        ],
        analyse: [
          // { code: 's1', name: '电能质量分析', com: AnalyseQualityIndex, args: {} },
          { code: 's1', name: '能耗分析', com: AnalyseEnergyIndex, args: {} },
          { code: 's2', name: '亮灯率分析', com: AnalyseLightOnIndex, args: {} },
          { code: 's3', name: '报警分析', com: AnalyseAlarmIndex, args: {} },
        ],
        pole: [
          { code: 's1', name: 'GIS地图', com: MapIndex, args: {} },
          { code: 's2', name: '灯杆详情', com: PoleMonitorIndex, args: {} },
          // {code: 's3', name: '资产概览', com: PoleAssetIndex, args: {}},
          { code: 's4', name: '设备操作', com: PoleControlIndex, args: {} },
          // {code: 's5', name: '电源设备管理', com: PowerConfigIndex, args: {}},
          // {code: 's6', name: '智能锁管理', com: ConfigLockIndex, args: {}},
          // {code: 's97', name: '灯杆设备', com: ConfigDeviceIndex, args: {}},
          // {code: 's98', name: '站点管理', com: ConfigStationIndex, args: {}},
          // { code: 's97', name: '多媒体任务管理', com: PoleMediaIndex, args: {} },
          { code: 's98', name: '设备管理', com: PoleParamIndex, args: {} },
          { code: 's99', name: '站点管理', com: PoleStationConfig, args: {} },
          { code: 'sub13', type: 0, name: '外接账号管理', com: ConfigAccountIndex, args: {tab:'s1'} },
          { code: 'sub14', type: 0, name: '联动管理', com: UnionIndex, args: {} },
          { code: 'sub15', type: 0, name: '供电管理', com: externalPowerIndex, args: {} },
          // { code: 'sub13', type: 0, name: '联动管理', com: ConfigAccountIndex, args: {} },
        ],
        // light: [
        //   { code: 's1', name: 'GIS地图', com: MapIndex, args: {} },
        //   { code: 's2', name: '统计概览', com: LightAnalyseIndex, args: {} },
        //   // {code: 's3', name: '网关管理', com: Working, args: {}},
        //   // { code: 's4', name: '灯具管理', com: ConfigLightIndex, args: {} },
        //   { code: 's5', name: '单灯控制', com: LightControlIndex, args: {} },
        //   // {code: 's6', name: '单灯触发', com: Working, args: {}},
        //   { code: 's7', name: '场景策略', com: Working, args: {} },
        //   { code: 's8', name: '分组管理', com: Working, args: {} },
        //   { code: 's9', name: '故障告警', com: Working, args: {} },
        //   { code: 's10', name: '历史数据', com: Working, args: {} },
        //   // {code: 's11', name: '触发设置', com: Working, args: {}},
        //   // {code: 's99', name: '分组管理', com: PoleGroupIndex, args: {}},
        // ],
        video: [
          { code: 's1', name: 'GIS地图', com: MapIndex, args: {} },
          { code: 's3', name: '设备详情', com: VideoControlIndex, args: {} },
          { code: 's4', name: '统计分析', com: VideoAnalysisIndex, args: {} },
          { code: 's5', name: '车牌识别', com: VideoVehicleIdIndex, args: {} },
          { code: 's6', name: '人脸识别', com: VideoFaceIdIndex, args: {} },
          { code: 's7', name: '历史回放', com: VideoHistoryIndex, args: {} },
          // { code: 's97', name: '参数配置', com: VideoConfigIndex, args: {} },
          // {code: 's95', name: '账号管理', com: ConfigAccountIndex, args: {}},
        ],
        broad: [//广播
          { code: 's1', name: 'GIS地图', com: MapIndex, args: {} },
          // { code: 's3', name: '设备详情', com: BroadOverviewIndex, args: {} },
          { code: 's4', name: '设备详情', com: BroadOverviewIndex2, args: {} },
          // {code: 's4', name: '定时发布', com: Working, args: {}},
          // { code: 's5', name: '广播节目管理', com: BroadProgramIndex, args: {} },
          { code: 's7', name: '设备操作', com: BroadControlIndex, args: {} },
          { code: 's6', name: '任务管理', com: BroadTaskIndex, args: {} },
          { code: 's5', name: '历史记录', com: BroadHistoryIndex, args: {} },
        ],
        speak: [//一键报警
          { code: 's1', name: 'GIS地图', com: MapIndex, args: {} },
          // { code: 's2', name: '设备详情', com: SpeakMonitorIndex, args: {} },
          { code: 's3', name: '设备详情', com: SpeakMonitorIndex2, args: {} },
          { code: 's4', name: '历史记录', com: SpeakHistoryIndex, args: {} },
          // {code: 's5', name: '触发器设置', com: Working, args: {}},
          // { code: 's97', name: '参数配置', com: SpeakConfigIndex, args: {} },
        ],
        led: [//广告屏
          { code: 's1', name: 'GIS地图', com: MapIndex, args: {} },
          { code: 's2', name: '设备详情', com: LedControlIndex2, args: {} },
          { code: 's3', name: '设备操作', com: LedControlIndex, args: {} },
          // { code: 's3', name: '节目管理', com: MediaAssetIndex, args: {} },
          { code: 's4', name: '任务管理', com: MediaTaskIndex, args: {} },
          { code: 's6', name: '历史记录', com: ledHistoryIndex, args: {} },
          // {code: 's5', name: '触发器设置', com: Working, args: {}},
          // { code: 's97', name: '参数配置', com: LedConfigIndex, args: {} },
        ],
        env: [//环境
          { code: 's1', name: 'GIS地图', com: MapIndex, args: {} },
          // { code: 's2', name: '设备详情', com: EnvMonitorIndex, args: {} },
          { code: 's22', name: '设备详情', com: EnvMonitorIndex2, args: {} },
          { code: 's3', name: '设备操作', com: EnvControlIndex, args: {} },
          { code: 's6', name: '历史记录', com: EnvHistoryIndex, args: {} },
          // {code: 's4', name: '定时发布', com: Working, args: {}},
          // {code: 's5', name: '触发器设置', com: Working, args: {}},
          // { code: 's97', name: '参数配置', com: EnvConfigIndex, args: {} },
        ],
        charge: [//充电桩
          { code: 's1', name: 'GIS地图', com: MapIndex, args: {} },
          // { code: 's2', name: '设备详情', com: ChargeMonitorIndex, args: {} },
          { code: 's22', name: '设备详情', com: ChargeMonitorIndex2, args: {} },
          { code: 's3', name: '充电记录', com: ChargeLogIndex, args: {} },
          { code: 's4', name: '收费规则', com: Working, args: {} },
          { code: 's5', name: '设备告警管理', com: Working, args: {} },
          // { code: 's6', name: '历史记录', com: ChargeHistoryIndex, args: {} },
          // { code: 's97', name: '参数配置', com: ChargeConfigIndex, args: {} },
        ],
        manhole: [//井盖
          { code: 's1', name: 'GIS地图', com: MapIndex, args: {} },
          // { code: 's3', name: '设备详情', com: ManholeMonitorIndex, args: {} },
          { code: 's4', name: '设备详情', com: ManholeMonitorIndex2, args: {} },
          { code: 's6', name: '历史记录', com: ManholeHistoryIndex, args: {} },
          // {code: 's4', name: '定时发布', com: Working, args: {}},
          // {code: 's5', name: '触发器设置', com: Working, args: {}},
          // { code: 's97', name: '参数配置', com: ManholeConfigIndex, args: {} },
        ],
        wifi: [
          { code: 's1', name: 'GIS地图', com: MapIndex, args: {} },
          // { code: 's2', name: '设备详情', com: WifiMonitorIndex, args: {} },
          { code: 's3', name: '设备详情', com: WifiMonitorIndex2, args: {} },
          // { code: 's6', name: '历史记录', com: WifiHistoryIndex, args: {} },
          // {code: 's4', name: '定时发布', com: Working, args: {}},
          // { code: 's97', name: '参数配置', com: WifiConfigIndex, args: {} },
          // {code: 's5', name: '触发器设置', com: Working, args: {}},
        ],
        garbage: [//垃圾
          { code: 's1', name: 'GIS地图', com: MapIndex, args: {} },
          // { code: 's3', name: '设备概览', com: GarbageMonitorIndex, args: {} },
          { code: 's32', name: '设备详情', com: GarbageMonitorIndex2, args: {} },
          { code: 's6', name: '历史记录', com: GarbageHistoryIndex, args: {} },
          // {code: 's3', name: '广播发布', com: Working, args: {}},
          // {code: 's4', name: '定时发布', com: Working, args: {}},
          // {code: 's5', name: '触发器设置', com: Working, args: {}},
          // { code: 's97', name: '参数配置', com: GarbageConfigIndex, args: {} },
        ],
        phone: [
          { code: 's1', name: 'GIS地图', com: MapIndex, args: {} },
          // { code: 's3', name: '设备概览', com: PhoneMonitorIndex, args: {} },
          { code: 's32', name: '设备详情', com: PhoneMonitorIndex2, args: {} },
          { code: 's6', name: '历史记录', com: PhoneHistoryIndex, args: {} },
          // {code: 's2', name: '设备管理', com: Working, args: {}},
          // {code: 's3', name: '广播发布', com: Working, args: {}},
          // {code: 's4', name: '定时发布', com: Working, args: {}},
          // { code: 's97', name: '参数配置', com: PhoneConfigIndex, args: {} },
          // {code: 's5', name: '触发器设置', com: Working, args: {}},
        ],
        traffic: [
          { code: 's1', name: 'GIS地图', com: MapIndex, args: {} },
        ],
      },
    }
  },
  computed: {
    ...mapGetters('auth', ['custAndType']),
    ...mapState('auth', ['token', 'user', 'customerId', 'customers', 'appTypes', 'config', 'appType', 'agentChangeValue', 'currentMenuName']),
    ...mapState('common', ['weeks']),
    ...mapState('group', ['devices', 'treeData', 'showDeviceTypes']),
    currentMenus: function () {
      return this.menus[this.currentMenuName] || [];
    },
  },
  watch: {
    agentChangeValue() {
    },
    currentMenuName() {
      this.currentMenu = 's1';
      this.collect = false;
      this.showCollect = true;
    },
  },
  created: function () {
    this.$store.commit('auth/setAppType', 'pole');
  },
  mounted: function () {
  },
  methods: {
    collectTree: function () {
      this.collect = !this.collect;
    },
    switchMenu: function (menu) {
      this.currentMenu = menu.code;
      // this.collect = this.currentMenu == 's99';
      // this.showCollect = this.currentMenu !== 's99';
    },
    switchTopMenu: function (params) {
      // console.log('switch menu', params)
      this.currentMenu = params.to;
    },
  },
}
</script>
<style scoped>
.left-spliter {
  position: absolute;
  left: 0;
  top: calc(50% - 35px);
  width: 14px;
  height: 70px;
  line-height: 70px;
  color: #ffffff;
  background: #007eff;
  border-radius: 0px 12px 12px 0px;
  cursor: pointer;
}
.left-spliter::after {
  position: absolute;
  content: " ";
  top: 30px;
  left: 5px;
  width: 10px;
  height: 10px;
  transform: rotate(45deg);
  border-left: solid 2px #ffffff;
  border-bottom: solid 2px #ffffff;
}
.right-spliter {
  position: absolute;
  left: 0;
  top: calc(50% - 35px);
  width: 14px;
  height: 70px;
  line-height: 70px;
  color: #ffffff;
  background: #007eff;
  border-radius: 0px 12px 12px 0px;
  cursor: pointer;
}
.right-spliter::after {
  position: absolute;
  content: " ";
  top: 30px;
  left: -1px;
  width: 10px;
  height: 10px;
  transform: rotate(225deg);
  border-left: solid 2px #ffffff;
  border-bottom: solid 2px #ffffff;
}
.monitor-main {
  width: 100%;
  height: 100%;
  display: flex;
}
.monitor-tree {
  width: 280px;
  flex: none;
}
.monitor-content {
  position: relative;
  /* border: solid 1px red; */
  background-color: #e9edf4;
  width: 720px;
  flex: auto;
  overflow: hidden;
  padding: 15px;
  display: flex;
  flex-direction: column;
}
.content-header {
  /* border: solid 1px red; */
  height: 46px;
  flex: none;
  margin-bottom: 15px;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  align-items: stretch;
}
.content-body {
  /* border: solid 1px red; */
  height: 400px;
  flex: auto;
  background: transparent;
  overflow: hidden;
  overflow-y: revert;
  /* padding: 10px; */
}
.header-item {
  line-height: 45px;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #0a1421;
  background-color: #ffffff;
  padding: 0 20px;
  cursor: pointer;
  user-select: none;
  /* border-right: solid 1px red; */
  margin-right: 1px;
}
.header-last-item {
  border-radius: 0 8px 8px 0;
  /* border-right: solid 1px red; */
}
.header-selected {
  color: #ffffff;
  background-color: #007eff;
}
</style>